import { ApplicationProvider } from '@griegconnect/krakentools-react-kraken-app'
import { createRoot } from 'react-dom/client'
import { AdminRouter } from './AdminRouter'
import * as serviceWorker from './serviceWorker'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <ApplicationProvider tool="kraken" name="NOxDigital Admin" reloadOnTenantsChange applicationSwitcher={false}>
    <AdminRouter />
  </ApplicationProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
