import { Box } from '@mui/material'
import React, { PropsWithChildren, useMemo } from 'react'

import { SearchField } from './SearchField'
import { FilterButton } from './FilterButton'
import { ActionsButton } from './ActionsButton'
import { SortDropdown, SortOption } from './SortDropdown'

export type Labels = {
  searchPlaceholder?: string
  sortingPlaceholder?: string
  filterLabel?: string
}

export type Props<D> = {
  labels?: Labels
  // Search
  onSearch?: (searchText: string) => void
  // Filtering
  onOpenFilter?: (refElement: HTMLButtonElement) => void
  // Actions
  onOpenActions?: (refElement: HTMLButtonElement) => void
  // Sorting
  sortOptions?: SortOption<D>[]
  onClientSideSort?: (sortFn: (data: D[]) => D[]) => void
  onServerSideSortChange?: (key: SortOption<D>['key']) => void
}

export function TableHeader<D>({
  onSearch,
  sortOptions,
  onClientSideSort,
  onServerSideSortChange,
  onOpenFilter,
  onOpenActions,
  labels,
}: PropsWithChildren<Props<D>>): React.ReactElement {
  const onFilterClickHandler = (refElement: HTMLButtonElement) => {
    onOpenFilter?.(refElement)
  }

  const onActionsClickHandler = (refElement: HTMLButtonElement) => {
    onOpenActions?.(refElement)
  }

  const onClientSideSortChangeHandler = (sortFn: (data: D[]) => D[]) => {
    onClientSideSort?.(sortFn)
  }

  const onServerSideSortChangeHandler = (key: SortOption<D>['key']) => {
    onServerSideSortChange?.(key)
  }

  const renderSortDropdown = useMemo(() => {
    if (sortOptions) {
      return (
        <SortDropdown
          placeholder={labels?.sortingPlaceholder}
          options={sortOptions}
          onClientSideSortChange={onClientSideSortChangeHandler}
          onServerSideSortChange={onServerSideSortChangeHandler}
        />
      )
    } else {
      return null
    }
  }, [sortOptions, onClientSideSortChangeHandler, onServerSideSortChangeHandler])

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={4}>
      <Box>{onSearch && <SearchField placeholder={labels?.searchPlaceholder} onSearch={onSearch} />}</Box>
      <Box display="flex" alignItems="center">
        {sortOptions && renderSortDropdown}
        {onOpenFilter && <FilterButton onFilterClick={onFilterClickHandler} label={labels?.filterLabel} />}
        {onOpenActions && <ActionsButton onActionsClick={onActionsClickHandler} />}
      </Box>
    </Box>
  )
}

export default TableHeader
