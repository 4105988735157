import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgArea = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M6 7h2l-3 3V8l1-1ZM12 7h-2l-4 4h2l4-4ZM14 7h2l-4 4h-2l4-4ZM15 16l4-4v-2l-4 4v2ZM19 14v2l-1 1h-2l3-3ZM18 7h1v1l-4.177 4.177c-.2-.443-.557-.8-1-1L18 7Z" />,
    <path d="M2 5a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1v-5H3a1 1 0 0 1-1-1V5Zm2 1v6h9a1 1 0 0 1 1 1v5h6V6H4Z" />
  )
export default SvgArea
