import { Dashboard, HostCompany, TimeCalendar, Vessel, Area } from '@griegconnect/krakentools-react-icons'
import { headerAtom, menuItemsAtom, useAuth } from '@griegconnect/krakentools-react-kraken-app'
import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'

const DashboardR = React.lazy(() => import('./routes/Dashboard'))
const PeriodsR = React.lazy(() => import('./routes/Periods'))
const TenantsR = React.lazy(() => import('./routes/Tenants'))
const VesselsR = React.lazy(() => import('./routes/Vessels'))
const AreasR = React.lazy(() => import('./routes/Areas'))
const routes = [
  {
    name: 'Dashboard',
    absolutePath: '/',
    icon: <Dashboard />,
    page: <DashboardR />,
  },
  {
    name: 'Areas',
    absolutePath: '/areas',
    icon: <Area />,
    page: <AreasR />,
  },
  {
    name: 'Periods',
    absolutePath: '/periods',
    icon: <TimeCalendar />,
    page: <PeriodsR />,
  },
  {
    name: 'Tenants',
    absolutePath: '/tenants',
    icon: <HostCompany />,
    page: <TenantsR />,
  },
  {
    name: 'Vessels',
    absolutePath: '/vessels',
    icon: <Vessel />,
    page: <VesselsR />,
  },
]

export const AdminRouter = () => {
  const setMenuItems = useSetRecoilState(menuItemsAtom)
  const [headerState, setHeaderState] = useRecoilState(headerAtom)
  const { isAuthenticated, loginWithRedirect, isInitializing } = useAuth()

  useEffect(() => {
    setHeaderState({ ...headerState, hideTenantSelector: true })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Only
    if (!isInitializing) {
      if (!isAuthenticated) {
        loginWithRedirect()
      } else {
        setMenuItems(routes)
      }
    }
  }, [isInitializing, isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  return isAuthenticated ? (
    <Routes>
      {routes.map((route) => (
        <Route path={route.absolutePath} element={route.page} key={route.absolutePath} />
      ))}
    </Routes>
  ) : null
}
